import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import StartProject from '../components/Common/StartProject'

const Services = () => {
    return (
        <Layout
            titulo="Nuestros servicios"
            descripcion="Servicios ofrecidos por do{main} México"
            slug="services"
            imagen="https://res.cloudinary.com/dariuus/image/upload/v1642537330/At_the_office_amico_33cd06c7d8.png"
        >
            <Navbar />

            <PageBanner
                pageTitle="Servicios" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Servicios" 
            />

            <ServicesOne />

            <StartProject />
            
            <Footer />
        </Layout>
    );
}

export default Services